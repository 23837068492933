@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
  font-family: GilroyBold;
  src: url("/static/media/Radomir Tinkov - Gilroy-Bold.633de190.woff");
}

@font-face {
  font-family: GilroyLight;
  src: url("/static/media/Radomir Tinkov - Gilroy-Light.32aa756b.woff");
}

@font-face {
  font-family: GilroyMedium;
  src: url("/static/media/Radomir Tinkov - Gilroy-Medium.e4d152ff.woff");
}

:root {
  --color-main: #32aaff;
  --color-secundary: #3066e3;
  --color-error: #FF4D6B;
  --color-success: #A4C54E;
  --color-blueVinilit: #005daa;
  --color-redNicoll: #cd181e;
  --color-redDurman: #ed2500;

  --color-darkUIMain: #000f1b;
  --color-darkUISecundary: #0c1b2b;

}

body {
  margin: 0;
  padding: 0;
  font-family: GilroyMedium;
  color: #111;
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: hidden;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 1920px;
  margin: auto;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #FFF0;
}
*::-webkit-scrollbar-thumb {
  background: #005daa;
  background: var(--color-blueVinilit);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #32aaff;
  background: var(--color-main);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: -20px;
  right: 0%;
  color: #FFF !important;
  background-color: #32aaff !important;
  background-color: var(--color-main) !important;
}

.closeModalSwal {
  position: absolute;
  top: -10px;
  right: -5px;

  outline: none !important;
  border: none !important;
}


/* ---------------------------- */

.preloader {
  width: 20px;
  height: 20px;

  border: 5px solid transparent;
  border-top: 5px solid #FFF;
  border-left: 5px solid #FFF;
  border-bottom: 5px solid #FFF;
  border-radius: 50%;

  margin: 10px auto;

  -webkit-animation-name: girar;

          animation-name: girar;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.preloaderPh {
  width: 50px;
  height: 50px;

  border: 10px solid transparent;
  border-top: 10px solid #32aaff;
  border-top: 10px solid var(--color-main);
  border-left: 10px solid #32aaff;
  border-left: 10px solid var(--color-main);
  border-bottom: 10px solid #32aaff;
  border-bottom: 10px solid var(--color-main);
  border-radius: 50%;

  margin: auto;

  -webkit-animation-name: girar;

          animation-name: girar;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes girar {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes girar {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* ----------------------------------------- */

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

